.a-logo {
    &__wrapper {
        @apply flex h-full content-center;

        padding: 20px 0 10px;
        margin-left: 0.5em;
        margin-right: 1.5em;

        @include mq("md") {
            margin-left: (100 - $container-width)/3;
            margin-right: 2em;
        }

        @include mq($from: lg, $until: xxl) {
            margin-left: 20px;
            margin-right: 20px;
        }

        @include mq("desktop") {
            padding: 20px 0;
        }
    }

    &__link {
        @apply flex items-center w-full;
    }

    &__image {
        height: ( $header-primary-height-mobile - 40px );

        @include mq("tablet") {
            height: ( $header-primary-height-tablet - 40px );
        }

        @include mq("desktop") {
            height: ( $header-primary-height-desktop - 50px );
        }
    }
}
