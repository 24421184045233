$page-max-width: 2200px;
$container-width: 86.5%;
$container-max-width: 1640px;
$content-max-width: ( $container-max-width - 300px );
$navbar-max-width: ( $page-max-width - 80px );
$header-max-width: $page-max-width;
$sidebar-width: calc(3vw + 200px);
$header-offset-mobile: $header-primary-height-mobile;
$header-offset-tablet: ( $header-primary-height-tablet + $header-secondary-height-tablet );
$header-offset-desktop: ( $header-primary-height-desktop + $header-secondary-height-desktop );
