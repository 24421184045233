.c-tease__wrapper--articles {
    .c-tease {
        &__inner {
            background-image: linear-gradient(180deg, #FAF6EF 0%, #FFFAF3 100%);
            border-radius: 15px;
        }

        &__image {
            border-radius: 15px 15px 0 0;

            img {}

            &.utl-hover-effect {
                @include attention() {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &__text {
            @apply p-6;

            &__date {}
            &__heading {}

            &__link {
                @apply block;

                transition: $transition--default;

                > svg {
                    width: 30px;
                }

                @include attention() {
                    margin-left: 10px;

                    > svg {
                        color: $color__secondary;
                    }
                }
            }
        }
    }
}
