.c-tease {
    position: relative;
    margin-top: 1em;
    min-height: 90px;
    padding: 0 10px;
    flex-basis: 100%;

    @include mq("sm") {
        flex-basis: 50%;
    }
    @include mq("lg") {
        flex-basis: 33.33%;
    }

    .utl-l-1,
    .utl-l-2 {
        @include mq("lg") {
            flex-basis: 40%;
        }
    }

    &__wrapper {
        @apply flex flex-wrap content-start;

        @include mq("desktop") {
            @apply mx-auto;
            max-width: $content-max-width;
        }

        &--2 {
            > .c-tease {
                @include mq("md") {
                    flex-basis: 50%;
                }
            }
        }

        &--3 {
            > .c-tease {
                @include mq("md") {
                    flex-basis: 50%;
                }
                @include mq("lg") {
                    flex-basis: 33.3%;
                }
            }
        }

        &--4 {
            > .c-tease {
                @include mq("md") {
                    flex-basis: 50%;
                }
                @include mq("lg") {
                    flex-basis: 25%;
                }
            }
        }

        &--5 {
            > .c-tease {
                @include mq("md") {
                    flex-basis: 33.3%;
                }
                @include mq("lg") {
                    flex-basis: 20%;
                }
            }
        }
    }

    &__inner {
        @apply flex flex-col relative h-full;
    }

    &__image {
        @apply overflow-hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: $transition--default;
        }
    }

    &__text {
        @apply py-4 flex flex-col flex-grow;
        line-height: $line-height-default;

        &__heading {
            margin: 0 0 10px;
            line-height: $line-height-small;
        }

        &__subheading {
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
            color: $color__primary;
            margin-bottom: 0 !important;
        }

        &__description {
            @apply flex-grow;
        }

        &__link {
            margin: 0 0 0.5em;
            text-decoration: none;
        }

        p {
            margin-bottom: 1em;
        }
    }

    &--teams {
        > .c-tease {
            margin: 0 1em;
        }
    }
}

@import "t.tease--articles";
@import "t.tease--entries";
@import "t.tease--blocks";
@import "t.tease--carousel";
