.c-alert {
    @apply w-full p-2;

    color: $color__white;
    background: #2E3D56;
    font-size: $font-size-small;
    text-align: center;

    .container {
        > * {
            @apply m-0;
        }
    }
}
