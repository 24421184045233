$mobile-nav-color: $color__white !default;
$mobile-nav-background: $color__primary !default;

.a-nav-toggle {
    @apply block self-center;

    @include mq("desktop") {
        @apply hidden;
    }

    &.utl-is-active {
        @apply relative z-50;
    }
}

.c-nav--mobile {
    &__wrapper {
        @apply self-center mx-auto;

        @include mq($until: desktop) {
            @apply flex justify-between items-center w-full h-full;
            max-width: 200px;
            margin-right: 0;
            padding-top: 20px;
            padding-right: (100 - $container-width)/3;
            background: $color__primary--alt;
        }
    }

    &__book {
        @extend .hidden--desktop;
        margin-left: -20px !important;
    }
}

.c-nav--primary__list-item-plus {
    @apply hidden;
}

@include mq( $until:desktop ) {
    .c-nav--primary {
        font-size: 1.15rem;
        width: $container-width;
        max-width: $container-max-width;
        @apply mx-auto;

        @include mq("lg") {
            width: $container-width * 0.9248554913;
        }

        &__wrapper {
            @apply hidden fixed z-40 overflow-auto;
            top: $header-primary-height-mobile;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $mobile-nav-background;
            padding-top: 20px;

            @include mq("lg") {
                top: $header-primary-height-tablet;
            }

            &.utl-is-active {
                @apply block;
            }
        }

        &__list {
            @apply flex-col p-0;

            &-item {
                @apply flex flex-wrap py-1 px-0;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgba(255,255,255,0.1);

                &:last-of-type {
                    border-bottom: none;
                }

                &-link {
                    @apply relative;
                    color: $mobile-nav-color;
                    border-radius: 40px;
                    padding: 5px 20px;
                    margin: 5px 0;

                    // &-caret {
                    //     display: none !important;
                    // }

                    > .a-icon {
                        margin-left: 0;
                    }

                    @include attention() {
                        background: transparent;
                        color: $mobile-nav-color;
                    }

                    &.utl-is-active {
                        background: $mobile-nav-color;
                        color: $color__secondary;

                        &::after {
                            background: transparent;
                        }

                        @include attention() {
                            &::after {
                                background: transparent;
                            }
                        }
                    }
                }

                @keyframes dropDownVisible {
                    0% {
                        top: -10px;
                        opacity: 0;
                    }

                    100% {
                        top: 0;
                        opacity: 1;
                    }
                }

                &.has-dropdown {
                    > .c-nav--primary__dropdown-menu {
                        display: none;
                        position: relative;
                        width: 100%;
                        margin-top: 0;
                        background: transparent;

                        &.utl-has-active {
                            display: block;
                            animation: dropDownVisible 0.5s ease-in-out;
                            animation-fill-mode: both;
                        }
                    }
                }

                &__plus {
                    @apply absolute block;
                    right: 0;
                    top: 0;
                    z-index: 0;
                    width: 58px;
                    height: 58px;
                    margin-top: 5px;

                    &::before {
                        content: '';
                        width: 15px;
                        height: 3px;
                        position: absolute;
                        top: 27px;
                        left: 24px;
                        background-color: $mobile-nav-color;
                        z-index: 1;
                    }

                    &[aria-expanded="false"] {
                        &::after {
                            content: '';
                            width: 3px;
                            height: 15px;
                            position: absolute;
                            top: 21px;
                            left: 30px;
                            background-color: $mobile-nav-color;
                            z-index: 2;
                        }
                    }

                    &[aria-expanded="true"] {
                        + .c-nav--primary__dropdown-menu {
                            @apply block;
                        }
                    }

                    &.utl-is-expanded {
                        + .c-nav--primary__dropdown-menu {
                            animation: dropDownVisible 0.5s ease-in-out;
                            animation-fill-mode: both;
                        }
                    }
                }
            }
        }

        &__dropdown-menu {
            @apply pl-4;

            &-item {
                &-link {
                    @apply inline-block relative;
                    color: $mobile-nav-color;
                    border-radius: 40px;
                    padding: 5px 20px;
                    margin: 5px 0;

                    @include attention() {
                        background: transparent;
                        color: $mobile-nav-color;
                    }

                    &.utl-is-active {
                        background: $mobile-nav-color;
                        color: $color__secondary;
                    }
                }
            }
        }
    }
}

.utl-menu-open {
    @include mq($until: desktop) {
        overflow: hidden;
    }
}

.c-nav--mobile__list-item {
    display: none;

    @include mq($until: desktop) {
        display: inline-flex !important;
        position: relative;
        align-self: center;
        color: #454444;
        padding: 3px 13px;
        font-weight: 500;
    }

    &__phone {
        @include mq($until: desktop) {
          @apply hidden;
        }
    }
}

.has-dropdown > .c-nav--primary__list-item-link {
    @include mq($until: desktop) {
        padding-right: 46px;
    }
}
