$banner-background-colour: #050406 !default;
$banner-text-colour: $color__white !default;
// Banner style (for multiple, comma separated). Use this as a variable and remove the styles
// you don't need so they won't get compiled in your css.
$banner-style: (
    slider,
    video,
    image
)
!default;

.banner {
    @apply relative overflow-hidden bg-cover;
    background-color: $banner-background-colour;

    &__border {
        @apply absolute;
        bottom: -2px;
        left: -5px;
        right: -5px;
        z-index: 2;

        svg {
            width: 100%;
            height: 100%;
        }
    }
    // &::after {
    //     @apply absolute;
    //     content: url("/assets/images/border--wave--banner.svg");
    //     bottom: -30px;
    //     left: -5px;
    //     right: -5px;
    //     z-index: 2;
    //     background-size: contain;
    //     background-position: right;
    // }
    &.banner--small {
        height: 275px;
        @include mq($from: lg, $until: 1799px) {
            height: 0;
            padding-bottom: 22% !important;
        }
        @include mq($from: 1800px) {
            height: calc(50vh - 247px);
            min-height: 300px;
            max-height: 440px;
        }
    }

    &.banner--standard {
        height: 400px;
        @include mq($from: lg, $until: 1799px) {
            height: 0;
            padding-bottom: 33.3% !important;
        }
        @include mq($from: 1800px) {
            height: calc(70vh - 247px);
            min-height: 400px;
            max-height: 640px;
        }
    }

    &.banner--large {
        height: 600px;
        @include mq($from: lg, $until: 1799px) {
            height: 0;
            padding-bottom: 50% !important;
        }
        @include mq($from: 1800px) {
            height: calc(100vh - 247px);
            min-height: 600px;
            max-height: 900px;
        }
    }

    &__text {
        @apply absolute z-10 w-full;
        top: 40%;
        transform: translateY(-50%);
        color: $banner-text-colour;

        &.banner__text--left {
            max-width: 800px;

            @include mq("lg") {
                max-width: 70vw;
            }
        }

        &.banner__text--center {
            @apply text-center;
        }

        &.banner__text--right {
            @apply text-right;
            max-width: 800px;
            right: 0;

            @include mq("lg") {
                max-width: 70vw;
            }
        }

        &__heading {
            @apply mb-2;
            color: $banner-text-colour;
            text-shadow: 0 -14px 33px rgba(0,0,0,0.5);
            font-size: 2.5em;
            line-height: $line-height-xsmall;
            font-weight: $font-weight-bold;

            @include mq("lg") {
                font-size: 4em;
                line-height: 1;
            }

            @include mq("xl") {
                font-size: 5em;
                line-height: 1;
            }

            @include mq($until: sm) {
                word-break: break-all;
            }
        }

        &__description {
            font-size: 1.2em;
            text-shadow: 0 -14px 33px rgba(0,0,0,0.5);
        }
    }

    &__image {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        width: 100%;
        @include mq($until: lg) {
            height: 100%;

            > picture {
                width: 100%;
                height: 100%;
                display: flex;

                > img {
                    object-fit: cover;
                    height: auto;
                    width: 100%;
                }
            }
        }

        &::before {
            @apply absolute w-full h-full;
            content: '';
            opacity: 0.6;
            background-color: rgba(255, 164, 46, 0.1);
            background-image: linear-gradient(90deg, rgba(0,0,0,0.54) 0%, rgba(0,0,0,0.00) 66%, rgba(0,0,0,0.00) 75%, rgba(0,0,0,0.54) 100%);
            z-index: 1;
        }
    }

    &__links {
        @apply inline mt-6;
        margin-left: -10px;
        margin-right: -10px;
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.16);
    }
}

.no-js .banner__image > picture {
    display: none;
}

@import 'banner-options/banner--slider';
@import 'banner-options/banner--video';
@import 'banner-options/banner--image';
@import 'banner-options/banner--icons';
