.circle {
    border-radius: 100%;
}

.visually-hidden {
    @extend %visually-hidden;
}

%box-shadow,
.box-shadow {
    box-shadow: 0 10px 90px rgba(0 , 0, 0, 0.2);
}

%box-shadow--lg,
.box-shadow--lg {
    box-shadow: 0 30px 120px rgba(0 , 0, 0, 0.15);
}

%responsive-iframe,
.responsive-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

.full-width {
    width: 100%;
}

.flex-grid {
  margin-left: -8px;
  margin-right: -8px;

  > * {
    padding: 8px;
  }
}

.centred, %centred {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.hidden--desktop {
    @include mq("desktop") {
        @apply hidden;
    }
}

.hidden--mobile {
    @include mq($until: desktop) {
        @apply hidden;
    }
}

.hidden--xs {
    @include mq($until: sm ) {
        @apply hidden;
    }
}

.hidden--sm {
    @include mq($from: sm, $until: md ) {
        @apply hidden;
    }
}

.hidden--md {
    @include mq($from: md, $until: lg ) {
        @apply hidden;
    }
}

.hidden--lg {
    @include mq($from: lg, $until: xl ) {
        @apply hidden;
    }
}

.hidden--xl {
    @include mq($from: xl, $until: xxl ) {
        @apply hidden;
    }
}

.hidden--xxl {
    @include mq("xxl") {
        @apply hidden;
    }
}

.no-js .js-lazy {
    display: none;
}

.loading {
    opacity: 0;
}

.loaded {
    opacity: 1;
}

.ie-support::after {
    content:'You are using a browser that is not supported. For a more consistent experience please use a different browser.';
    position: absolute;
    top: 100%;
    left: 0;
    padding: 20px;
    text-align: center;
    width: 100vw;
    background: $color__blue;
    color: $color__white;
    display: block;
}

figure {
    margin: 2rem 0;
    max-width: 100% !important;

    > img {
        display: inline-block !important;
    }
}

.alert {
    @apply p-4 mb-8;
    background-color: #f2f2f2;

    > p {
        @apply mb-0;
    }
}

.alert-danger {
    background-color: #ffe3e3;
}

.alert--success {
    background-color: #88C695;
}

.grecaptcha-badge {
    transform: translateY(-70px);
}
