@import "../blocks/b.buttons";
@import "../blocks/b.entries";
@import "../blocks/b.image-text";
@import "../blocks/b.video-text";
@import "../blocks/b.images";
@import "../blocks/b.shutters";
@import "../blocks/b.text";

.content-block {
    + .content-block {
        @apply my-12;
    }

    + .b-buttons__wrapper {
        @apply m-0;
    }
}
