$color__primary: #264F91; // primary buttons
$color__primary--alt: #1855A0; // primary hover effects
$color__primary--dark: #113A7C; // primary hover effects
$color__secondary: #D11337; // secondary buttons
$color__secondary--alt: #BD2531;
$color__tertiary: #E1A42E; // footer and testimonial borders

// Go make your own at https://meyerweb.com/eric/tools/color-blend/#EFEFEF:333333:6:hex
$color__grey--100: #efefef;
$color__grey--200: #d4d4d4;
$color__grey--300: #b9b9b9;
$color__grey--400: #9e9e9e;
$color__grey--500: #848484;
$color__grey--600: #696969;
$color__grey--700: #4E4E4E;
$color__grey--800: #333333;

$color__link: $color__secondary;
$color__link--attention: $color__primary;

$color__page-background: $color__grey--200;

$color__white: #fff;
$color__black: #000;
$color__red: #ce1f06;
$color__orange: #ee8600;
$color__yellow: #ffd404;
$color__blue: #4870B8;
$color__green: #048040;

$swiper__theme: $color__white;
