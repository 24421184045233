.c-commonQuestions {
    @apply relative pt-12 pb-20;

    background: $color__primary;

    &__heading {
        @apply mb-8;
        color: $color__white;
        text-align: center;
    }
}
