$sidebar-heading-color: $color__secondary !default;
$sidebar-heading-color--active: $color__secondary !default;
$sidebar-heading-color--hover: $color__primary--alt !default;
$sidebar-link-color: $color__primary !default;
$sidebar-link-color--active: $color__primary--alt  !default;
$sidebar-link-color--hover: $color__primary--alt !default;
$sidebar-bg-color: $color__grey--100 !default;
.c-sidebar {
    @apply my-4;
    padding: 2rem 1rem;
    background-color: $sidebar-bg-color;

    @include mq("desktop") {
        @apply sticky mt-16;
        top: $header-primary-height-desktop;
    }

    &__heading {
        @apply block pt-4 pb-2 my-0;
        color: $sidebar-heading-color;
        text-decoration: none;
        font-weight: $font-weight-bold;

        &--active {
            color: $sidebar-heading-color--active;
        }

        &--nested {
            @apply block py-0 my-0;
            margin-bottom: 5px;
        }

        &:matches(a) {
            @include attention() {
                color: $sidebar-heading-color--hover;
            }
        }
    }
    &__list {
        @apply ml-4;

        &-item {
            @apply relative;
            line-height: $line-height-large;

            &__link {
                @apply block my-2 px-3;
                color: $sidebar-link-color;
                text-decoration: none;
                border-radius: 15px;
                transition: $transition--default;

                @include attention() {
                    background-color: $color__grey--100;
                    color: $sidebar-link-color--hover;
                }

                &.utl-is-active {
                    color: $color__white;
                    background-color: $color__secondary;

                    @include attention() {
                        color: $color__white;
                        background-color: $color__secondary;
                    }
                }
            }

            &__descendants {
                @apply hidden;

                .c-sidebar__list-item {
                    @apply ml-4;
                }
            }
        }
    }

    &__plus {
        @apply absolute;
        width: 30px;
        height: 30px;
        display: block;
        left: -32px;
        top: 0px;

        &::before {
            content: '';
            width: 15px;
            height: 3px;
            position: absolute;
            top: 13px;
            left: 8px;
            background-color: $color__grey--300;
        }

        &[aria-expanded="false"] {
            &::after {
                content: '';
                width: 3px;
                height: 15px;
                position: absolute;
                top: 7px;
                left: 14px;
                background-color: $color__grey--300;
            }
        }

        &[aria-expanded="true"] {
            + .c-sidebar__list-item__link + .c-sidebar__list-item__descendants {
                @apply block;
            }
        }

        &.utl-is-expanded {
            + .c-sidebar__list-item__link + .c-sidebar__list-item__descendants {
                animation: dropDownVisible 0.5s ease-in-out;
                animation-fill-mode: both;
            }
        }
    }
}
