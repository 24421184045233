$shutter-title-background: $color__grey !default;
$shutter-title-hover-background: $color__primary !default;
$shutter-title-active-background: $color__secondary !default;
$shutter-title-active-hover-background: $shutter-title-hover-background !default;
$shutter-title-active-color: $color__white !default;
$shutter-title-active-hover-color: $color__white !default;
$shutter-title-color: $color__secondary !default;
$shutter-title-hover-color: $color__white !default;
$shutter-transition: all 0.25s ease-in-out !default;

@if index($blocks, shutters) {
    .js .b-shutters__shutter__content {
        overflow: hidden;
        height: 0;
    }

    .b-shutters {

        &__shutter {
            &__title {
                @apply relative flex w-full py-4 px-8;
                justify-content: space-between;
                border: 4px solid $shutter-title-background;
                border-radius: 25px;
                color: $shutter-title-color;
                background: $shutter-title-background;
                text-align: left;
                outline: 2px solid transparent;
                cursor: pointer;
                margin-bottom: 10px;
                font-weight: $font-weight-bold;
                transition: $shutter-transition;
                align-items: center;

                @include mq("md") {
                    font-size: 1.2em;
                }

                > svg {
                    @apply block;
                    height: 36px;
                    width: 36px;
                    color: $shutter-title-color;
                    transition: $shutter-transition;
                    align-self: center;
                    z-index: 2;
                }

                @include attention() {
                    margin-left: -10px;

                    // color: $shutter-title-hover-color;
                    // background: $shutter-title-hover-background;
                    //
                    // > svg {
                    //     color: $shutter-title-hover-color;
                    // }
                }

                &.utl-is-active {
                    color: $shutter-title-active-color;
                    background: $shutter-title-active-background;

                    svg {
                        color: $shutter-title-active-color;
                        transform: rotate(90deg);
                    }

                    + .b-shutters__shutter__content {
                        height: 100%;
                    }
                }
            }

            &__content {
                margin-bottom: 1px;

                &__inner {
                    @apply py-4 px-5;
                    color: $text-color;

                    &.background-colored {
                        color: $color__white;
                    }
                }
            }
        }
    }
}
