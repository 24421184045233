.a-prevNext {
    @apply flex justify-between pt-0;

    max-width: 80%;
    margin: 0 auto 2em;

    &__more {
        text-align: center;
        text-transform: uppercase;
        color: $color__grey--700;
        font-size: $font-size-small;
        letter-spacing: 2px;
    }

    &__link {
        @apply flex items-center;

        &--left {
            margin-right: auto;

            svg {
                margin-right: 10px;
            }
        }

        &--right {
            margin-left: auto;

            svg {
                margin-left: 10px;
            }
        }
    }

    svg {
        color: $color__secondary;
        width: 25px;
    }
}
