.m-activity {}

.m-specialisedActivities {
    @apply relative pt-12 pb-20;
    background: $color__primary;
    min-height: 1000px;

    @include mq("md") {
        padding-bottom: 300px;
    }

    &__border {
        @apply absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        @extend .container--small;
        text-align: center;
        color: $color__white;
    }

    &__container {
        @extend .container;
        @apply flex flex-wrap justify-between;
    }

    &__filters {
        @apply flex flex-wrap justify-center mb-4;

        &__button {
            color: $color__white;
            font-weight: $font-weight-medium;
            border: 1.8px solid $color__white;
            padding: 8px 20px;
            border-radius: 25px;
            transition: $transition--default;
            @include mq("md") {
                padding: 8px 40px;
            }

            + .m-specialisedActivities__filters__button {
                margin-left: 10px;
                @include mq("md") {
                    margin-left: 1em;
                }
            }
            @include attention() {
                color: $color__primary;
                background: $color__white;
            }

            &.utl-is-active {
                color: $color__primary;
                background: $color__white;
            }
        }
    }

    .m-specialisedActivity {
        @apply relative m-4;
        color: $color__white;
        width: 85%;
        @include mq("sm") {
            width: 42%;
        }
        @include mq("lg") {
            width: 28%;
        }
        @include attention() {
            @include mq("md") {
                > .m-specialisedActivity__hover{
                    position: absolute;
                    display: block;
                    top: 90%;
                    left: -5px;
                    width: 480px;
                    height: auto;
                    border: 15px solid $color__white;
                    border-radius: 6px;
                    z-index: 2;

                    img {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }

                    iframe {
                        width: 100%;
                        min-height: 300px;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: -30px;
                        left: 5%;
                        width: 0;
                        height: 0;
                        border-left: 20px solid transparent;
                        border-right: 20px solid transparent;
                        border-bottom: 15px solid $color__white;
                    }

                    &.utl-position--right {
                        transform: translateX(-380px);

                        &::after {
                            left: 85%;
                        }
                    }
                }
            }
        }

        &__hover {
            @apply hidden;
        }
    }
}
