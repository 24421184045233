.c-tease__wrapper--block {
    .c-tease {
        max-height: 280px;

        &__inner {
            position: relative;
            border-radius: 15px;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.6) 100%);
                height: 50%;
                pointer-events: none;
            }

            &__heading {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 0 1em;
                color: $color__white;
                text-shadow: 0 -14px 33px rgba(0,0,0,0.5);
                z-index: 2;
                pointer-events: none;
            }
        }

        &__image {
            border-radius: 15px;
        }

        @include attention() {
            cursor: pointer;

            .utl-hover-effect {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}
