.b-videoText {
    @include mq("lg") {
        @apply flex items-center;
    }

    &__video {
        @include mq("lg") {
            min-height: 500px;
            width: 80%;
            padding-right: 2em;
            transition: $transition--default;

            &.utl-is-active {
                transform: translateX(5%);
            }
        }
    }

    &__content {
        z-index: 1;
        transition: $transition--default;
        @include mq("lg") {
            @apply flex flex-col justify-center p-6;
            width: 35%;
        }

        > *:first-child {
            margin-top: 0;
        }
        @include mq("lg") {
            &.utl-is-inactive {
                opacity: 0.5;
                transform: translateX(-15%);
            }
        }
    }

    &.utl-position--left {
        @include mq("lg") {
            .b-videoText__video {
                padding-right: 2em;
                margin-right: -15%;
            }
        }
    }

    &.utl-position--right {
        @include mq("lg") {
            @apply flex-row-reverse justify-end;

            .b-videoText__video {
                padding-left: 2em;
                margin-left: -15%;
            }
        }
    }

    &.utl-position--full {
        @apply flex flex-col-reverse;

        .b-videoText {
            &__content {
                @apply p-0 text-left w-full;
                background: transparent;

                @include mq("md") {
                    @apply text-center;
                }
            }

            &__video {
                background: transparent;

                iframe {
                    @apply w-full mx-auto;
                }
            }
        }
    }
}
