.m-events {
    &__wrapper {
        @apply relative my-12;
    }

    &__filters {
        position: absolute;
        top: 1em;
        right: 0;
        padding-right: 10px;
        color: $color__grey--700;
    }

    &__date {
        @apply my-8;
        text-align: center;
    }

    &__rsvp {
        @apply mb-2;
        font-weight: $font-weight-bold;
        font-size: $font-size-small;

        &__date {}

        &__available {
            color: $color__tertiary;
        }

        &__soldout {
            color: $color__red;
        }
    }

    &__list {
        @extend .list--unstyled;

        &__item {
            @apply relative mb-8;

            background-image: linear-gradient(180deg, #FAF6EF 0%, #FFFAF3 100%);
            border-radius: 15px;

            &__image {
                @apply overflow-hidden;

                border-radius: 15px 15px 0 0;

                @include mq("md") {
                    @apply w-1/3;

                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: 15px 0 0 15px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    transition: $transition--default;
                }

                &.utl-hover-effect {
                    @include attention() {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            &__inner {
                @apply p-8;

                @include mq("md") {
                    margin-left: 34%;
                }

                @include mq("lg") {
                    @apply flex items-center justify-between;
                }

                &__content {
                    @include mq("lg") {
                        @apply pr-8;
                    }

                    &__date {
                        font-weight: $font-weight-semibold;
                    }

                    &__heading {
                        margin: 10px 0;
                    }

                    &__cost {
                        margin: 10px 0;
                        padding-left: 1em;
                        font-size: $font-size-small;
                    }
                }

                &__button {
                    align-self: center;
                    white-space: nowrap;
                    box-shadow: 0 7px 14px 0 rgba(0,0,0,0.16);
                }
            }
        }
    }
}

.m-eventsListing {
    &__image {
        @apply mb-8;
        max-height: 500px;
        overflow: hidden;
    }

    &__date {
        margin-top: 1em;
    }

    &__rsvp {
        @apply mb-2;
        font-weight: $font-weight-bold;
        font-size: $font-size-small;

        &__date {
            position: relative;

            &::before {
                content: '-';
                padding: 0 1em;
            }
        }

        &__custom {
            position: relative;

            &::before {
                content: '-';
                padding: 0 1em;
            }
        }

        &__available {
            color: $color__tertiary;
        }

        &__soldout {
            color: $color__red;
        }
    }
}
