$button-primary-color: $color__white !default;
$button-primary-color--hover: $color__white !default;
$button-primary-bg-color: $color__primary !default;
$button-primary-bg-color--hover: $color__primary--alt !default;
$button-secondary-color: $color__white !default;
$button-secondary-color--hover: $color__white !default;
$button-secondary-bg-color: $color__secondary !default;
$button-secondary-bg-color--hover: $color__primary !default;
$button-default-color: $color__black !default;
$button-default-color--hover: $color__black !default;
$button-default-bg-color: $color__grey--100 !default;
$button-default-bg-color--hover: $color__grey--200 !default;
$button-border-color: $color__primary !default;
$button-border-color--hover: $color__white !default;
$button-border-bg-color: $color__primary !default;
$button-border-bg-color--hover: $color__primary !default; // Choose gradient or flat

%a-button,
.a-button {
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight-semibold;
    padding: 8px 25px;
    letter-spacing: 0.25px;
    border-radius: 50px;
    text-decoration: none;

    + .a-button {
        margin-left: 1em;
    }

    &__arrow {
        @apply inline;
        @apply ml-2 mr-12;
        transition: margin $timing--default $ease-std;
    }

    &--primary {
        color: $button-secondary-color;
        background: $button-secondary-bg-color;
        transition: all $timing--default $ease-std;

        @include attention() {
            color: $button-secondary-color--hover;
            background: $button-secondary-bg-color--hover;
        }
    }

    &--secondary {
        background: $color__white;
        color: $color__secondary;
        transition: all $timing--default $ease-std;

        @include attention() {
            background: $color__primary;
            color: $color__white;
        }
    }

    &--default {
        color: $button-default-color;
        background: $button-default-bg-color;

        @include attention() {
                color: $button-default-color--hover;
                background: $button-default-bg-color--hover;
        }
    }

    &--dark {
        color: $color__white;
        background: darken($color__secondary, 15%);
        border: 2px solid $color__white;
        border-radius: 4px;
        padding: 5px 30px;

        @include attention() {
            color: $color__white;
            background: darken($color__primary, 15%);
            border: 2px solid $color__white;
            padding: 5px 30px;
        }
    }

    &--outline {
        color: $button-border-color;
        border: 1px solid $button-border-bg-color;

        @include attention() {
            color: $button-border-color--hover;
            background: $button-border-bg-color--hover;
        }
    }

    &--arrow {
        @apply relative overflow-hidden pl-0;
        text-indent: -999px;

        @include attention() {
            @apply pl-2;
        }

        &::after {
            @apply absolute z-10;
            content: "";
            background-image: url("/assets/images/icon--arrow--right.svg");
            color: $button-primary-bg-color;
            width: 23px;
            height: 10px;
            right: 56px;
            top: 18px;
            background-size: contain;
        }
    }

    &--book {
        color: $button-secondary-color;
        background: $button-secondary-bg-color;
        transition: all $timing--default $ease-std;
        box-shadow: 0 7px 14px 0 rgba(0,0,0,0.16);
        align-self: center;
        white-space: nowrap;

        @include attention() {
            color: $button-secondary-color;
            transform: translateY(-2px);
        }
    }

    &--submit {
        @extend .a-button--secondary;
    }

    &--more {
        margin: 0;
    }

    &--search {
        @apply flex items-center;
        margin: 0 0 0 -1px;
        transform: translate(0);
        border-radius: 0 5px 5px 0;
        padding: 10px 40px;

        svg {
            height: 20px;
            width: 20px;
            margin-right: 0.5em;
        }
    }

    &--download {
        &__page {
            @include mq("md") {
                float: right;
                margin-top: -$font-size-h2 - 1em;
            }
        }
    }

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.utl-button-arrows {
    .a-button {
        @apply relative pr-16;
        @include attention() {
            @apply pr-20;
        }

        &::after {
            @apply absolute z-10;
            content: "";
            background-image: url("/assets/images/icon--arrow--right.svg");
            color: $button-primary-color;
            width: 23px;
            height: 10px;
            right: 30px;
            top: 18px;
            background-size: contain;
        }
    }
}
