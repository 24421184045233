.c-share {
    @apply sticky flex flex-col items-center self-start;
    background: $color__secondary;
    color: $color__white;
    padding: 10px;
    border-radius: 0 10px 10px 0;
    z-index: 10;
    top: $header-offset-tablet + 20px;

    @include mq("desktop") {
      top: $header-offset-desktop + 20px;
    }

    &__wrapper {
        display: none;
        position: absolute;
        align-self: flex-start;
        left: 0;
        height: 100%;
        top: $header-offset-tablet + 60px;

        @include mq("md") {
            display: block;
        }

        @include mq("desktop") {
          top: $header-offset-desktop + 90px;
        }

        &.banner-padding {
            top: $header-offset-tablet + 420px;

            @include mq("desktop") {
              top: $header-offset-desktop + 450px;
            }
        }
    }

    span {
        font-size: $font-size-small;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-self: center;
    }


    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color__white;
        margin: 10px auto;

        @include attention() {
            color: $social-icons-link-color--hover;
        }

        svg {
            height: 25px;
            width: 25px;
        }
    }
}

.home {
    .o-content__content {
        padding: 0;
    }

    .c-share {
        &__wrapper {
            top: $header-offset-tablet + 620px;

            @include mq("desktop") {
              top: $header-offset-desktop + 650px;
            }
        }
    }
}
