.c-tease--carousel {
        max-width: 100vw;
        padding-bottom: 2em !important;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
       scrollbar-width: none;  /* Firefox */

       @include mq("desktop") {
           @apply mx-auto;
           max-width: $content-max-width;
       }

       &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
        }

        .c-tease {
            height: auto;
            width: 100%;

            @include mq($until: lg) {
                max-width: 75vw;
                width: 350px;
            }

            &__wrapper {
                flex-wrap: nowrap;
            }
        }

    &.swiper-container {
        .swiper-wrapper {
            box-sizing: border-box;
        }

        .swiper-pagination,
        .swiper-pagination-bullets {
            bottom: 0;

            @include mq("lg") {
                display: none;
            }

            .swiper-pagination-bullet {
                background: $color__primary--dark !important;
            }
        }
    }
}
