@if index($blocks, images) {
    .b-images {
        &--default {
            display: flex;
            flex-wrap: wrap;
        }

        &__image {
            &--default {
                flex: 1 1 25%;
            }
        }

        &--carousel {
            &.swiper-container {
                > .swiper-pagination-bullets {
                    left: 50% !important;
                    right: auto !important;
                    transform: translate(-50%) !important;
                    width: auto !important
                }

                .swiper-button {
                    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
                        left: 85% !important;
                        border-right: none;
                        border-top-left-radius: 40px;
                        border-bottom-left-radius: 40px;
                        transform: translateX(-100%);

                        @include mq("md") {
                            left: 90% !important;
                        }
                    }

                    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
                        right: 15% !important;
                        margin-right: 1px;
                        border-left: none;
                        border-top-right-radius: 40px;
                        border-bottom-right-radius: 40px;
                        transform: translateX(100%);

                        @include mq("md") {
                            right: 10% !important;
                        }
                    }
                }
            }
        }
    }
}

.glightbox-container {
    .gclose {
        svg {
            margin: 0 auto;
        }
    }
}
