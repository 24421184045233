.c-featured {
    @apply relative py-8;
    background: $color__tertiary;

    &__border {
        @apply absolute;
        top: -1px;
        left: 0;
        right: 0;
        z-index: 2;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    > .container {
        @include mq("md") {
            @apply flex items-center py-16;
        }
    }

    &__image {
        height: 250px;
        min-width: 350px;
        max-width: 80%;
        transform: rotate(-2deg);
        border-radius: 15px;
        margin: 0 auto 50px;
        overflow: hidden;

        @include mq("md") {
            height: 300px;
            min-width: 40%;
            margin: 0 50px 0 0;
            transform: rotate(-4deg);
        }

        @include mq("desktop") {
            min-width: 30%;
            margin-right: 100px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}
