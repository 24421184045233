html {
    font-family: $font-family-default;
    font-size: $font-size-default;
    line-height: $line-height-default;
    font-weight: $font-weight-regular;
    color: $text-color;

    @include mq($from: sm, $until:lg) {
        font-size: $font-size-default * 0.9;
    }

    @include mq($from: lg, $until:xl) {
        font-size: $font-size-default * 0.9;
    }

    @include mq($from: xl, $until:xxl) {
        font-size: $font-size-default;
    }

    @include mq($from: xxl, $until:xxxl) {
        font-size: $font-size-default;
    }

    @include mq("xxxl") {
        font-size: $font-size-default * 1.1;
    }
}

h1, %h1, .h1,
h2, %h2, .h2,
h3, %h3, .h3,
h4, %h4, .h4,
h5, %h5, .h5,
h6, %h6, .h6 {
    font-family: $font-family-default;
    line-height: $line-height-small;
    margin-top: 1rem;
    margin-bottom: 1rem;
    // color: $headings-color;

    @include mq("md") {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    + h1, %h1, .h1,
    h2, %h2, .h2,
    h3, %h3, .h3,
    h4, %h4, .h4,
    h5, %h5, .h5,
    h6, %h6, .h6 {
        margin-top: 0 !important;
    }
}

.h1,
%h1,
h1, {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: $font-size-h1--mobile;

    @include mq("md") {
        font-size: $font-size-h1;
    }
}

.h2,
%h2,
h2 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: $font-size-h2--mobile;

    @include mq("md") {
        font-size: $font-size-h2;
    }
}

.h3,
%h3,
h3 {
    font-weight: $headings-font-weight;
    font-size: $font-size-h3--mobile;

    @include mq("md") {
        font-size: $font-size-h3;

    }
}

.h4,
%h4,
h4 {
    font-weight: $font-weight-medium;
    font-size: $font-size-h4--mobile;

    @include mq("md") {
        font-size: $font-size-h4;

    }
}

.h5,
%h5,
h5 {
    font-weight: $font-weight-medium;
    font-size: $font-size-h5--mobile;

    @include mq("md") {
        font-size: $font-size-h5;

    }
}

.h6,
%h6,
h6 {
    font-weight: $font-weight-medium;
    font-size: $font-size-h6--mobile;

    @include mq("md") {
        font-size: $font-size-h6;

    }
}

// Paragraphs
// --------------------------------------------------

p {
    margin-bottom: $paragraph-spacing;
    line-height: $line-height-default;

    &:last-of-type {
        // margin-bottom: 0;
    }
}

.two-columns p:last-of-type {
    margin-bottom: $paragraph-spacing;
}

// Links
// --------------------------------------------------

a {
    color: $color__link;
    transition: color $timing--default linear;
    text-decoration: underline;

    @include attention {
        color: $color__link--attention;
    }
}

// Lists
// --------------------------------------------------

ul li {
    // line-height: $line-height-large;
}

// Styles
// // --------------------------------------------------

.two-columns {
    @include mq("md") {
        column-count: 2;
        column-gap: 100px;

        > *:first-child {
            margin-top: 0;
        }
    }
}

// Quotes
// // --------------------------------------------------

q {
    quotes: "“" "";

    &::before {
        content: open-quote;
    }
}

blockquote {
    @apply my-8;

    font-family: $font__tertiary;
    font-style: italic;
    font-size: 2rem;
    color: $color__grey--600;
    line-height: 1.1;
    padding-left: 1em;
    border-left: 4px $color__grey--600 solid;
}

hr {
    @apply p-0 mt-4 mb-10;

    border-top: 1px solid $color__primary;
}

figcaption {
    @apply my-4;

    color: $color__grey--700;
    font-size: 0.9rem;
    font-style: italic;
}

%strong,
.strong,
strong {
    font-weight: $font-weight-bold;
}

%underlined,
.underlined {
    @apply relative;

    padding-bottom: 25px;

    &::after {
        @apply absolute;

        content: "";
        bottom: 0;
        left: 0;
        height: 2px;
        width: 50px;
        background: $color__grey--600;
    }
}

%underlined--centered,
.underlined--centered {
    @extend %underlined;

    &::after {
        left: 50%;
        margin-left: -25px;
    }
}

.lead {
    font-weight: $font-weight-bold;
}

.small {
    font-size: $font-size-small;
}

.o-content__content {
    ul:not(.list--unstyled),
    ol:not(.list--unstyled)  {
        line-height: $line-height-large;
        margin-bottom: $paragraph-spacing;
        margin-left: 1em;
    }

    ul:not(.list--unstyled) {
        list-style-type: disc;

        > li > ul {
            list-style-type: circle;

            > li > ul {
                list-style-type: square;
            }
        }
    }

    ol:not(.list--unstyled) {
        list-style-type: decimal;

        > li > ol {
            list-style-type: lower-alpha;

            > li > ol {
                list-style-type: lower-roman;
            }
        }
    }

    .list--unstyled {
        list-style: none;
    }
}

// Definition Lists

dl {
    @apply my-4;

    overflow: hidden;
    padding: 0;
}

dt {
    float: left;
    width: 50%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 13px 0;
    margin: 0;

    &:first-of-type {
        border-top: none;
    }
}

dd {
    float: left;
    width: 50%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 13px 0;
    margin: 0;

    &:first-of-type {
        border-top: none;
    }
}

.utl-highlighted {
    padding: 0px 6px 2px 6px;
    background-color: $color__tertiary;
}
