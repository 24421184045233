@if index($blocks, buttons) {
    .b-buttons {
        @for $i from 2 to 4 {
            &:nth-child(n+#{$i}) {
                @include mq("lg") {
                    animation-delay: $i * 0.15s;
                }
            }
        }

        &__wrapper {
            @apply flex items-center flex-wrap;


            &.utl-align--center {
                @apply justify-center;
            }

            @include mq("lg") {
            }

            &--list {
                @apply flex-col items-start my-4;

                > .b-buttons__button {
                    @apply p-0 mb-4;
                    background: none;
                    color: $color__link;

                    @include attention() {
                        @apply p-0;
                        background: none;
                        color: $color__link--attention;
                    }

                    + .b-buttons__button {
                        @apply ml-0;
                    }
                }
            }
        }

        &__heading {
            @include mq("lg") {
                @apply px-20;
            }
        }

        &__button {
            margin: 2px 0;
        }

        @include attention() {}
    }


    .js-animate--start > .b-buttons {
        @extend %js-animate--slide-down;
    }
}
