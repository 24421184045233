// --------------------------------------------------------------------
// Tease Module
// --------------------------------------------------------------------
$tease-my: 30px;
$tease-mb: 1.5rem;
$tease-types: (
    card,
    image-block,
    default
);
$card-tease-background-colour: $color__grey--100;
$card-tease-heading-weight: $font-weight-bold;

// --------------------------------------------------------------------
// Header
// --------------------------------------------------------------------
$header-primary-height-mobile: 100px;
$header-primary-height-tablet: 100px;
$header-primary-height-desktop: 110px;
$header-primary-background-color: $color__white;

$header-secondary-background-color: $color__secondary;
$header-secondary-height-tablet: 0;
$header-secondary-height-desktop: 0;

// --------------------------------------------------------------------
// Primary Nav
// --------------------------------------------------------------------
$nav-primary-link-color: $color__white;
$nav-primary-link-color--hover: $nav-primary-link-color;
$nav-primary-link-underline-color: $color__tertiary;
$nav-primary-link-underline-color--hover: $nav-primary-link-underline-color;
$nav-primary-link-underline-color--mobile: $nav-primary-link-underline-color;
$nav-primary-link-caret-color: $color__secondary;
$nav-primary-dropdown-background-color: $color__secondary;
$nav-primary-dropdown-link-color: $color__white;
$nav-primary-dropdown-link-color--hover: $color__white;
$nav-primary-dropdown-link-background-color--hover: $color__primary;
$nav-primary-link-spacing: 18px;

// --------------------------------------------------------------------
// Secondary Nav
// --------------------------------------------------------------------
$nav-secondary-link-color: $color__white;
$nav-secondary-link-color--hover: $color__white;

// --------------------------------------------------------------------
// Mobile Nav
// --------------------------------------------------------------------
// collapse,converge,cris-cross,default,minus,scissor-alt,scissor,stack,twist
$hamburger-animations: (collapse);
$hamburger-animation-speed: 1.5;
$hamburger-color: $color__white;
$hamburger-size: 40px;
$hamburger-thickness: 3px;
$mobile-nav-color: $color__white;
$mobile-nav-background: $color__primary;
// full, default
$mobile-nav-height: (
    default
)
!default;


// --------------------------------------------------------------------
// Sidebar
// --------------------------------------------------------------------
$sidebar-heading-color: $color__secondary;
$sidebar-heading-color--active: $color__secondary;
$sidebar-heading-color--hover: $color__primary--alt;
$sidebar-link-color: $color__primary;
$sidebar-link-color--active: $color__primary--alt ;
$sidebar-link-color--hover: $color__primary--alt;
$sidebar-bg-color: transparent;

// --------------------------------------------------------------------
// Footer Primary
// --------------------------------------------------------------------
$footer-primary-background: $color__tertiary;
$footer-primary-text-link-color: $color__black;
$footer-primary-text-link-color--hover: $color__primary--alt;

// --------------------------------------------------------------------
// Footer Secondary
// --------------------------------------------------------------------
$footer-secondary-background: $color__white;
$footer-secondary-text-color: $color__grey--700;
$footer-secondary-text-link-color: $color__link;
$footer-secondary-text-link-color--hover: $color__primary--alt;
$footer-secondary-text-link-decoration: none;
$footer-secondary-text-link-decoration--hover: none;

// ---------------------------------------------------------------------------
// Banners
// ---------------------------------------------------------------------------
$banner-background-colour: #050406;
$banner-text-colour: $color__white;
// Banner style (for multiple, comma separated). Use this as a variable and remove the styles
// you don't need so they won't get compiled in your css.
$banner-style: (
    slider,
    //video,
    // image
);

// ---------------------------------------------------------------------------
// Breadcrumbs
// ---------------------------------------------------------------------------
$breadcrumbs-link-color: $color__secondary;
$breadcrumbs-link-color--hover: $color__primary;
$breadcrumbs-link-color--active: $color__grey--600;
$breadcrumbs-separator-color: $color__grey--600;

// ---------------------------------------------------------------------------
// Pagination
// ---------------------------------------------------------------------------
$pagination-m: 1.5em 0;
$pagination-link-p: 23px 33px;
$pagination-link-bg: $color__grey--100;
$pagination-link-color: $color__black;
$pagination-link-bg--hover: $color__primary;
$pagination-link-color--hover: $color__white;
$pagination-link-bg--current: $color__primary;
$pagination-link-color--current: $color__white;
$pagination-link-spacing: 1px;
$pagination-alignment: (
    //left,
    center,
    //right
)
!default;
$pagination-link-transition: $transition--default;

// --------------------------------------------------------------------
// Social Icons
// --------------------------------------------------------------------
$social-icons-link-color--footer: $color__secondary;
$social-icons-link-color--navigation: $nav-secondary-link-color;
$social-icons-link-color--hover: $color__primary--alt;
