.c-tease__wrapper--entries {
    @include mq("lg") {
        justify-content: center;
    }

    .c-tease {
        &__wrapper {
            @apply justify-center;
        }

        &__inner {
            background-image: linear-gradient(180deg, #FAF6EF 0%, #FFFAF3 100%);
            border-radius: 15px;
        }

        &__image {
            border-radius: 15px 15px 0 0;

            img {}

            &.utl-hover-effect {
                @include attention() {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &__text {
            @apply p-6;

            &__heading {
                color: $text-color;

                &__link {
                    text-decoration: none;
                }
            }

            &__links {
                margin: 1em 0 10px;

                &__button {
                    margin-right: 10px;
                    box-shadow: 0 7px 14px 0 rgba(0,0,0,0.16);
                }

                &__unavailable {
                    @apply block italic;

                    color: $color__grey--800;
                }

                &__more {
                    font-weight: $font-weight-bold;
                    text-decoration: none;
                }
            }
        }
    }

    &.c-tease__wrapper--events {
        .c-tease {
            &__text {
                &__links{
                    &__more {
                        content: '>';
                    }
                }
            }
        }
    }
}

.c-tease__wrapper--camps {
    &__subheading {
        font-weight: $font-weight-bold;
    }

    &__cost {
        @apply flex mb-4;

        > p {
            @apply m-0;
        }

        > svg {
            height: 20px;
            width: 20px;
            margin-right: 1em;
            margin-top: 4px;
        }
    }

    &__schedule {
        @apply flex mb-4;

        &__time {
            > p {
                margin-bottom: 5px;
            }
        }

        > svg {
            height: 20px;
            width: 20px;
            margin-right: 1em;
            margin-top: 4px;
        }
    }
}

.c-tease__wrapper--accommodation {
    @apply relative mb-8;
    z-index: 3;

    &__amenities {
        @extend .list--unstyled;
        @apply flex flex-wrap items-center my-6;

        &__item {
            @apply flex items-center w-1/4;

            > svg {
                height: 20px;
                width: 20px;
                margin-right: 5px;

                &.icon--check {
                    height: 10px;
                    margin: 0;
                }
            }
        }
    }

    &__floorplan {
        display: none;
        position: fixed;
        width: 480px;
        height: auto;
        border: 15px solid $color__white;
        background: $color__white;
        border-radius: 6px;
        pointer-events: none;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        &::after {
            content: '';
            position: absolute;
            top: -30px;
            left: 5%;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 15px solid $color__white;
        }

        &.utl-position--right {
            transform: translateX(-380px);

            &::after {
                left: 85%;
            }
        }
    }

    .c-tease {
        @include attention() {
            z-index: 99;

           > .c-tease__wrapper--accommodation__floorplan {
               display: block;
           }
        }
    }
}
