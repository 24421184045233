.c-footer {
    @apply relative pt-12;

    background-image: linear-gradient(180deg, #B7084A 1%, #BD2531 100%);;
    color: $color__white;

    &__border {
        @apply absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        z-index: 2;
        margin-bottom: -1px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    #footerForm {
        padding-top: 100px;
        margin-top: -100px;
    }

    &--contact {
        @extend .container--small;

        &__above {
            @apply w-full;
            text-align: center;
        }

        form {
            p {
                text-align: center;
            }
        }
    }

    &--bottom {
        @apply flex flex-wrap justify-center pt-8;

        @include mq("md") {
            @apply justify-between items-end;
        }
    }


    &__list {
        @apply flex flex-wrap justify-center w-full py-12 mx-4;

        &-item {
            + .c-footer__list-item::before {
                content: "|";
                padding: 0 10px;
            }

            &__link {
                color: $color__white;
                font-weight: $font-weight-bold;
                letter-spacing: 0.2px;
                text-decoration: none;

                @include attention() {
                    color: $color__tertiary;
                }
            }
        }
    }

    &__copyright {
        @extend .container--left, .container--right;
        @apply w-full;
        font-size: $font-size-small;
        text-align: center;

        &__link {
            color: $color__white;
            text-decoration: none;

            @include attention() {
                color: $color__tertiary;
            }
        }
    }

    &__socials {
        @apply w-full;

        @include mq("md") {
            @apply w-auto order-3 m-4;
        }

        > .c-socialIcons {
            @apply justify-center;
        }
    }


    &__assets {
        height: 55px;

        @include mq("md") {
            @apply order-5 m-4;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__credit {
        @apply w-full my-4;
        font-size: $font-size-small;
        text-align: center;

        @include mq("md") {
            @apply w-auto order-4 m-4;
        }

        &__link {
            color: $color__white;
            text-decoration: none;

            @include attention() {
                color: $color__tertiary;
            }
        }
    }
}
