$shutter-title-background: $color__primary--dark;
$shutter-title-hover-background: $color__primary--dark;
$shutter-title-active-background: $color__white;
$shutter-title-active-hover-background: $shutter-title-hover-background;
$shutter-title-active-color: $color__primary--dark;
$shutter-title-active-hover-color: $color__primary--dark;
$shutter-title-color: $color__white;
$shutter-title-hover-color: $color__primary--dark;
$shutter-transition: all 0.25s ease-in-out;

$blocks: (
    buttons,
    call-to-action,
    entries,
    image-text,
    images,
    shutters,
    text
)
!default;

@import '../blocks/b.buttons';
@import '../blocks/b.entries';
@import '../blocks/b.image-text';
@import '../blocks/b.images';
@import '../blocks/b.shutters';
@import '../blocks/b.text';
