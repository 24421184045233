// ---------------------------------------------------------------------------
// Tools
// ---------------------------------------------------------------------------
@tailwind base;
@tailwind utilities;
@import "tools/t.attention";
@import "tools/t.box-sizing";

// ---------------------------------------------------------------------------
// Settings
// ---------------------------------------------------------------------------
@import "settings/s.colours";
@import "settings/s.mq";
@import "settings/s.typography";
@import "settings/s.animations";
@import "settings/s.atoms";
@import "settings/s.blocks";
@import "settings/s.components";
@import "settings/s.containers";

//--------------------------------------------------------------------
// Base
// ---------------------------------------------------------------------------
@import "base/base.helpers";
@import "base/base.tables";
@import "base/base.typography";

// ---------------------------------------------------------------------------
// Objects
// ---------------------------------------------------------------------------
@import "objects/o.containers";
@import "objects/o.homepage";

// --------------------------------------------------------------------
// Components
// --------------------------------------------------------------------
// @import "../css/tabby.css";
@import "components/c.header";
@import "components/navs/c.nav--toggle";
@import "components/navs/c.nav--primary";
@import "components/navs/c.nav--secondary";
@import "components/navs/c.nav--site-map";
@import "components/navs/c.nav--mobile";
@import "components/c.sidebar";
@import "components/c.footer";
@import "components/c.alert";
@import "components/c.banners";
@import "components/c.featured";
@import "components/c.testimonial";
@import "components/c.breadcrumbs";
@import "components/c.pagination";
@import "components/c.share";
@import "components/c.social-icons";
@import "components/c.subscribe";
@import "components/c.common-questions";

// --------------------------------------------------------------------
// Blocks
// --------------------------------------------------------------------
@import "components/c.content-blocks";

// ---------------------------------------------------------------------------
// Buttons
// ---------------------------------------------------------------------------
@import "atoms/a.buttons";

// ---------------------------------------------------------------------------
// Misc Atoms
// ---------------------------------------------------------------------------
@import "atoms/a.alerts";
@import "atoms/a.forms";
@import "atoms/a.icons";
@import "atoms/a.logo";
@import "atoms/a.prevNext";
@import "atoms/a.skipLink";

// --------------------------------------------------------------------
// Teases
// --------------------------------------------------------------------
@import "teases/t.tease";

// ---------------------------------------------------------------------------
// Modules
// ---------------------------------------------------------------------------
@import "modules/m.articles";
@import "modules/m.activities";
@import "modules/m.camps";
@import "modules/m.accomodation";
@import "modules/m.events";
// ---------------------------------------------------------------------------
// Trumps
// ---------------------------------------------------------------------------
@import "trumps/t.animations";
@import "trumps/t.colours";
@import "trumps/t.hover-effects";
@import "trumps/t.typography";
@import "trumps/t.ui";
