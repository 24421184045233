$nav-primary-link-color: $color__grey--700 !default;
$nav-primary-link-color--hover: $color__primary--alt !default;
$nav-primary-link-underline-color: $color__primary !default;
$nav-primary-link-underline-color--hover: $color__grey--700 !default;
$nav-primary-link-underline-color--mobile: $color__primary--alt !default;
$nav-primary-link-caret-color: $color__primary !default;
$nav-primary-dropdown-background-color: $color__grey !default;
$nav-primary-dropdown-link-color: $color__secondary !default;
$nav-primary-dropdown-link-color--hover: $color__white !default;
$nav-primary-dropdown-link-background-color--hover: $color__primary !default;
$nav-primary-link-spacing: 18px !default;

.c-nav--primary {
    @apply w-full items-center;

    @include mq("desktop") {
        @apply flex;
        font-size: 0.9em;
    }

    &__wrapper {
        @apply flex flex-grow flex-wrap;

        background: $color__primary--alt;
        padding-right: (100 - $container-width)/3;

        @include mq($from: lg, $until: xxl) {
            padding-right: 20px;
            padding-left: 10px;
        }
    }

    &__button {
        @extend .hidden--mobile;

        @include mq("desktop") {
            margin-left: auto !important;
        }
    }

    &__list {
        @apply flex justify-center w-full list-none;

        &-item {
            @apply relative flex;
            padding: 0 ($nav-primary-link-spacing / 2);

            &-link {
                @apply relative self-center text-center;
                color: $nav-primary-link-color;
                padding: 8px ($nav-primary-link-spacing / 2);
                text-decoration: none;

                &--search {
                    @apply pb-2;
                }

                // &-caret {
                //     @include mq("desktop") {
                //         @apply inline self-center ml-1;
                //         color: $nav-primary-link-caret-color;;
                //     }
                // }

                &::after {
                    @apply absolute w-0;
                    content: "";
                    left: 0;
                    bottom: -5px;
                    height: 5px;
                    background: $nav-primary-link-underline-color--mobile;
                    transition: 0.2s width ease-in;

                    @include mq("desktop") {
                        background: $nav-primary-link-underline-color;
                    }
                }

                @include attention() {
                    @include mq("desktop") {
                        color: $nav-primary-link-color--hover;

                        &::after {
                            @apply w-full;

                            background-color: $nav-primary-link-underline-color--hover;
                        }
                    }
                }

                &.utl-is-active {
                    &::after {
                        @apply w-full;
                    }

                    @include attention() {
                        @include mq("desktop") {
                            &::after {
                                @apply w-full;
                                background-color: $nav-primary-link-underline-color--hover;
                            }
                        }
                    }
                }
            }
        }
    }

    &__dropdown-menu {
        @apply hidden absolute list-none;

        @include mq("desktop") {
            margin-top: 0;
            background: $nav-primary-dropdown-background-color;
            top: 100%;
            min-width: 250px;
            transition: all 0.3s ease;
            border-top: $nav-primary-dropdown-background-color 5px solid;
            border-radius: 0 0 15px 15px;
            padding: 5px 0 10px;
            z-index: 2;
        }

        &-item {
            @apply block;

            &-link {
                text-decoration: none;

                @include mq("desktop") {
                    @apply block;
                    color: $nav-primary-dropdown-link-color;
                    padding: 10px 18px;
                    transition: all 0.15s ease-in-out;
                    line-height: 1.3;
                }

                @include attention() {
                    @include mq("desktop") {
                        color: $color__white;
                        background-color: $nav-primary-dropdown-link-background-color--hover;
                    }
                }

                &.utl-is-active {
                    @include mq("desktop") {
                        background-color: $nav-primary-dropdown-link-background-color--hover;
                        color: $nav-primary-dropdown-link-color--hover;
                    }
                }
            }
        }
    }
}

.navbar--centre {
    @include mq("desktop") {
        @apply flex-col items-center;

        .c-nav--primary {
            &__wrapper {
                @apply w-full;
            }

            &__list {
                @apply justify-center;
            }
        }
    }
}

@keyframes dropDownVisible {
    0% {
        left: -4px;
        opacity: 0;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}


.has-dropdown {
    @include mq("desktop") {
        @include attention() {
            > .c-nav--primary__dropdown-menu {
                display: block;
                animation: dropDownVisible 0.2s ease-in;
                animation-fill-mode: both;
            }

            .c-nav--primary__list-item-link {
                color: $nav-primary-link-color--hover;

                &::after {
                    width: 0;
                    background-color: transparent;
                }
            }
        }

        &:focus-within {
            .c-nav--primary__dropdown-menu {
                display: block;
                animation: dropDownVisible 0.2s ease-in;
                animation-fill-mode: both;
            }
        }
    }
}
