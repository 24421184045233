@if index($banner-style, slider) {
    .banner--slider {
        > .swiper-wrapper {
            @include mq($until: 1799px) {
              position: absolute !important;
            }
        }

        + .banner-icon {
            &__wrapper {
                @include mq("desktop") {
                    top: $header-offset-desktop + 120px;
                }
            }
        }

        &.swiper-container-banner {
            .swiper-button {
                .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
                    left: 85% !important;
                    border-right: none;
                    border-top-left-radius: 40px;
                    border-bottom-left-radius: 40px;
                    transform: translateX(-100%);

                    @include mq("md") {
                        left: 90% !important;
                    }
                }

                .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
                    right: 15% !important;
                    margin-right: 1px;
                    border-left: none;
                    border-top-right-radius: 40px;
                    border-bottom-right-radius: 40px;
                    transform: translateX(100%);

                    @include mq("md") {
                        right: 10% !important;
                    }
                }
            }
        }
    }

    .swiper-pagination {
        bottom: 100px;
    }

    .swiper-pagination-bullet {
        background: $color__white !important;

        + .swiper-pagination-bullet {
            margin-left: 10px;
        }
    }
}

@include mq($until:sm) {
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        left: 5px !important;
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        right: 5px !important;
    }

    .swiper-button-next:after, .swiper-button-prev:after {
        font-size: 2rem !important;
    }
}

@include mq($until:375px) {
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        left: 0px !important;
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        right: 0px !important;
    }

    .swiper-button-next:after, .swiper-button-prev:after {
        font-size: 1.75rem !important;
    }
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev,
.swiper-button-prev, .swiper-container-rtl .swiper-button-next, {
    top: auto !important;
    bottom: 20px !important;
    opacity: 1 !important;
    padding: 0 1em;
    height: 30px !important;
    transition: $transition--default;
    background: lighten($color__primary, 5%);

    &::after {
        color: $color__white !important;
        font-size: 12px !important;
    }

    @include attention() {
        background: lighten($color__primary, 8%);
    }

    &.swiper-button-disabled {
        background: $color__primary !important;

        @include attention() {
            background: $color__primary !important;
        }
    }

    @include attention() {
        .swiper-button-next:after, .swiper-button-prev:after {
            color: $color__secondary !important;
        }
    }
}
