form {
    // display: flex;
    // flex-wrap: wrap;
    max-width: 800px;

    label {
        display: inline-block;
        padding-bottom: 0.5rem;
    }

    input,
    textarea,
    select {
        display: block;
        width: 100%;
        border: 1px solid #B57E14;
        font-family: $font-family-default;
        color: $text-color;
        background: $color__white;
        transition: 0.3s ease-in-out border-color;
        border-radius: 15px;
        font-size: 1rem;
        padding: 10px 20px;

        &:focus {
            outline: none;
            border-color: $color__primary;
        }

        &::placeholder {
            color: $text-color;
        }
    }

    input[type='radio'],
    input[type='checkbox'] {
        display: inline;
        width: auto;
        margin: 0 10px 0 0;
    }

    button {
        @extend %a-button;
        @extend %a-button--primary;
    }
}

.a-form {
    &__wrapper {
        @apply mx-auto;

        > form {
            @apply flex flex-wrap;
        }
    }

    &__group {
        @apply w-full;

        &.half-field {
            @include mq("md") {
                @apply w-1/2;
                padding: 0 2px;
            }
        }
    }

    &__label {}
    &__control {}

    &__checkbox,
    &__radio,
    &__select {
        width: 100%;

        label {
            display: flex;
            align-items: center;
        }
    }

    &--search {
        @apply flex mb-12;

        label {
            @apply w-full;
            padding: 0;

            input {
                @apply w-full m-0;
                border-radius: 5px 0 0 5px;
            }
        }
    }

    &--sib {
        @apply flex flex-wrap justify-center text-center;

        &__notice {
            @apply hidden;

            &.utl-is-active {
                @apply block;
            }
        }

        input,
        textarea,
        select {
            border: 2px solid $color__white;
            background: transparent;
            color: $color__white;
            border-radius: 4px;
            padding: 5px 10px;

            &::placeholder {
                color: $color__white;
            }
        }
    }
}

.alert {
    @apply p-4 mb-8;

    &--success {
        color: $color__white;
        background-color: $color__green;
    }

    &--danger {
        color: $color__white;
        background-color: $color__red;
    }
}

.freeform {
    &-row {
        @include mq($until: sm) {
            display: block !important;
        }
    }

    &-column {
        padding: 5px 0 !important;
        margin: 0 5px !important;

        label:empty {
            padding: 0;
        }

        .freeform-signature-wrapper {
            border-radius: 5px !important;

            > button {
                padding: 2px 10px !important;
                background: $color__black !important;
                border-radius: 5px !important;
                font-size: $font-size-small !important;
            }
        }
    }

    &-label {}
    &-input {
        &.form-datepicker {
            @apply relative;

            &::after {
                content: url("/assets/images/icon--calendar.svg");
                position: absolute;
                right: 5px;
                z-index: 1;
            }
        }
    }

    &-submit {
        margin-top: 1em;
        box-shadow: 0 7px 14px 0 rgba(0,0,0,0.16);
    }
}

.c-form {
    &__wrapper {}
    &__tabs {
        @apply flex justify-center;

        &__button {
            @extend .a-button, .a-button--outline;
            color: $color__white;
            border-color: $color__white;
            border-radius: 0;
            margin: 0 !important;
            outline: 0;

            &:first-of-type {
                border-radius: 4px 0 0 4px;
            }

            &:last-of-type {
                border-radius: 0 4px 4px 0;
            }

            &.utl-is-active {
                color: $color__secondary;
                background: $color__white;
            }
        }
    }

    &__form {
        @apply hidden;

        &.utl-is-active {
            @apply block;
        }
    }
}

#vlEnquiryForm {
    .vl-form-block {
        @apply flex flex-wrap justify-center text-center;
        border: none;
        padding: 1em;

        h1, %h1, .h1,
        h2, %h2, .h2,
        h3, %h3, .h3,
        h4, %h4, .h4,
        h5, %h5, .h5,
        h6, %h6, .h6 {
            width: 100%;
            color: $color__white;
        }

        .vl-form-object {
            label {
                color: $color__white;
                display: inline-block;
                padding-bottom: 0.5rem;
            }

            input,
            textarea,
            select {
                display: block;
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0;
                border-bottom: 1px solid #B57E14;
                font-family: $font-family-default;
                color: $text-color;
                background: $color__white;
                transition: 0.3s ease-in-out border-color;
                border-radius: 15px;
                font-size: 1rem;
                padding: 10px 20px;
            }
        }
    }

    .vl-form-button {
        display: flex;
        justify-content: center;
        > input {
            display: block;
            align-items: center;
            font-weight: $font-weight-semibold;
            padding: 20px 25px;
            letter-spacing: 0.25px;
            border-radius: 50px;
            text-decoration: none;
            color: $button-primary-color;
            background: $button-primary-bg-color;
            transition: all $timing--default $ease-std;
            width: 200px;
            text-align: center;
            font-weight: 500;
            text-transform: capitalize;
            font-size: 18px;

            @include attention() {
                color: $button-secondary-color--hover;
                background: $button-secondary-bg-color--hover;
            }
        }
    }
}

.ui-widget-header {
    width: 400px;
}

.ui-datepicker-div {
    width: 410px;
}