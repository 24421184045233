.banner-icon {
    margin-bottom: 10px;
    text-align: center;
    text-decoration: none;
    transition: $transition--default;

    @include mq($until: desktop) {
        + .banner-icon {
            margin-left: 2em;
        }
    }

    @include attention() {
        transform: scale(1.1);
    }

    &__icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        margin: 0 auto 10px;
        border-radius: 100%;
        background-image: linear-gradient(180deg, #196292 0%, #0E498A 100%);
        box-shadow: 0 7px 14px 0 rgba(0,0,0,0.16);
        transition: $transition--default;

        > svg {
            height: 20px;
            width: 20px;
            color: $color__white;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: center;
        margin: 10px 0;
        @include mq("desktop") {
            position: absolute;
            top: $header-offset-desktop + 50px;
            flex-direction: column;
            right: 8%;
            z-index: 10;
        }
    }

    &__title {
        color: $text-color;
        font-size: $font-size-small;
        @include mq("desktop") {
            @apply hidden;
        }
    }
}
