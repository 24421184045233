.c-featuredActivites {
    // @apply mb-24;

    &__container {
        @extend .container--large;

        @include mq($until: lg) {
            width: 100%;
            max-width: none;
        }
    }

    &__content {
        @extend .container--small;
        text-align: center;
    }
}

.c-activitiesList {
    @apply relative pt-12 pb-16;
    color: $color__white;
    background-image: linear-gradient(180deg, #BA1D31 0%, #A70941 71%, #95074F 100%);

    @include mq("md") {
        text-align: center;
    }

    &__border--top {
        @apply absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        z-index: 2;
        margin-bottom: -1px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__border--bottom {
        @apply absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 2;
        margin-top: -1px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    ul {
        @apply w-full;
        list-style-type: disc;
        text-align: left;
        max-width: 75%;
        margin: 0 auto;

        @include mq("sm") {
            column-count: 2;
            column-gap: 100;
        }

        @include mq("desktop") {
            column-count: 4;
        }
    }
}

.c-aboutHome {
    @apply flex pt-20 pb-12;
    margin-top: -20px;
    background-image: linear-gradient(180deg, #FAF6EF 0%, #FFFAF3 100%);
    text-align: center;

    @include mq("md") {
        @apply pb-16;
        text-align: left;
    }

    &__image {
        height: 250px;
        max-width: 80%;
        transform: rotate(-2deg);
        border-radius: 15px;
        margin: 0 auto 50px;
        overflow: hidden;

        @include mq("md") {
            height: 350px;
            min-width: 40%;
            margin: 0 50px 0 0;
            transform: rotate(-4deg);
        }

        @include mq("desktop") {
            min-width: 30%;
            margin-right: 100px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.c-upcomingEvents {
    @apply relative py-12;

    &__content {
        @extend .container--small;

        @include mq("md") {
            text-align: center;
        }
    }

    &__container {
        @extend .container--large;

        @include mq($until: lg) {
            width: 100%;
            max-width: none;
        }
    }
}

.c-homeMap {
    @apply relative;

    margin-top: 220px;

    @include mq("md") {
        margin-top: 2em;
    }

    &__border {
        @apply absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__heading {
        @apply mt-0 mb-2;
        font-size: 2em;
    }

    &__contact {
        @apply absolute flex flex-col justify-center;

        top: -200px;
        left: 50%;
        height: 320px;
        width: 320px;
        padding: 1em 1.8em;
        border-radius: 15px;
        background: $color__primary;
        transform: translate(-50%) rotate(2deg);
        color: $color__white;
        text-align: center;
        z-index: 2;

        @include mq("md") {
            top: -25px;
            left: 10%;
            padding: 2em 2.4em;
            transform: rotate(-4deg);
        }

        &__link {
            display: flex;
            justify-content: center;
            color: $color__white;
            margin-top: 1em;
            margin-left: -5px;
            text-decoration: none;

            @include attention() {
                color: $color__secondary;
            }

            svg {
                height: 20px;
                width: 30px;
            }

            &--phone {
                align-items: center;
                margin-right: 10px;
            }
        }
    }
}
