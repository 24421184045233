.m-articlesListing{
    &__text {
        @apply relative pt-2 pb-2;

        background-color: rgba(210, 210, 210, 0.95);

        &.utl-has-image {
            background-color: rgba(220, 220, 220, 0.8);
        }
    }

    &__heading {
        color: $color__grey--600;
        transition: $transition--default;
        &-link {
            @apply block p-4;

            text-decoration: none;
            min-height: 255px;
        }
    }

    &__details {
        color: $color__grey--600;
        transition: $transition--default;
    }

    &__description {
        font-size: 1.1em;
        font-style: italic;
    }

    &__body {}

    @include attention() {
        .m-articlesListing__text {
            @apply pt-2 pb-2;

            color: $color__white;
            background-color: $color__primary;
        }

        .m-articlesListing__heading {
            color: $color__white;
        }

        .m-articlesListing__details {
            color: $color__white;
        }

    }
}

.m-articlesListing__text.utl-has-image {
    top: 0;
}
