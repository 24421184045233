@if index($blocks, image-text) {
    .b-imageText {
        @apply mt-8 mb-2;
        @include mq("lg") {
            @apply flex;
        }

        &--standard {
            > .b-imageText__image {
                max-height: 300px;
                overflow: hidden;

                img {
                    object-fit: cover;
                }
                @include mq("lg") {
                    max-height: 600px;

                    img {
                        object-fit: contain;
                    }
                }
            }

            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        img {
                            object-fit: cover;
                            height: 100%;
                        }
                    }
                }
            }
        }

        &--large {
            > .b-imageText__image {
                img {
                    object-fit: cover;
                }
            }

            .swiper-container {
                @include mq("md") {
                    min-height: 500px;
                }

                .swiper-wrapper {
                    .swiper-slide {
                        img {
                            object-fit: cover;
                            height: 100%;
                        }
                    }
                }
            }
        }

        &__image {
            @include mq("lg") {
                @apply w-1/2;
            }
            @include mq($from:xl, $until:xxl) {
                @apply flex;
            }

            > picture {
                @include mq($from:xl, $until:xxl) {
                    @apply flex justify-center overflow-hidden;
                }

                > img {
                    @apply w-full h-full;
                    object-fit: cover;
                    object-position: center;
                    @include mq($from:xl, $until:xxl) {
                        max-width: initial;
                    }
                }
            }
        }

        .b-images--carousel {
            @include mq($until: lg) {
                @apply mb-4;
            }
        }

        &__text {
            @apply py-8 px-0;
            @include mq("lg") {
                @apply w-1/2 flex flex-col justify-center;
            }

            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }

            &.utl-has-background-color {
                @apply px-8;
            }
        }

        &.utl-position--left {
            > .b-imageText__text {
                @include mq("lg") {
                    @apply pl-8;

                    &.utl-has-background-color {
                        @apply ml-2;
                    }
                }
                @include mq("xl") {
                    @apply pl-12;
                }
            }
        }

        &.utl-position--right {
            @include mq("lg") {
                @apply flex-row-reverse;
            }

            > .b-imageText__text {
                @include mq("lg") {
                    @apply pr-8;

                    &.utl-has-background-color {
                        @apply mr-2;
                    }
                }
                @include mq("xl") {
                    @apply pr-12;
                }
            }
        }

        .swiper-container {
            @include mq("lg") {
                max-width: 50%;
            }
        }
    }
}
